@mixin text {
	font-family: var(--lc-font-text);
}

@mixin mono {
	font-family: var(--lc-font-mono);
	font-feature-settings: var(--lc-fontsettings-mono);
}
@mixin code {
	font-family: var(--lc-font-mono);
	font-feature-settings: var(--lc-fontsettings-code);
}
