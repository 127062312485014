a:any-link {
	color: inherit;
	cursor: pointer;
	text-decoration: initial;
}

input[type='search']::-webkit-search-cancel-button {
	filter: invert(100%) sepia(1000%) saturate(0%) hue-rotate(90deg);
	cursor: pointer;
}
