@use 'colors';
@use 'font';

@import 'styles/foundation/reset.css';
@import 'styles/foundation/font.scss';
@import 'styles/foundation/rem.scss';
@import 'styles/foundation/colors.scss';
@import 'styles/foundation/elems.scss';

:global {
	html,
	body,
	#app-root {
		height: 100%;
	}

	#app-root {
		display: flex;
		flex-direction: column;
		max-width: 100vw;
	}

	body {
		@include colors.block-white;
		@include font.text;
		overscroll-behavior: none;
	}

	select,
	input,
	textarea,
	button {
		@include font.text;
	}
}
