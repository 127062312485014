:root {
	font-size: 10px;

	@media (max-height: 900px) or (max-width: 1100px) {
		font-size: 9px;
	}
	@media (max-height: 800px) or (max-width: 977px) {
		font-size: 8px;
	}
	@media (max-height: 700px) or (max-width: 855px) {
		font-size: 7px;
	}
}

body {
	font-size: 1.6rem;
}
