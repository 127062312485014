:root {
	--lc-color-light-contrast: #3c4a54;
	--lc-color-dark-contrast: #ffffff;

	--lc-color-yellow: #fec800;
	--lc-color-purple: #831f82;
	--lc-color-green: #80b266;
}

:root,
[data-theme='light'] {
	--lc-color-bluegray: #3c4a54;
	--lc-color-lavender: #e8d2e8;
	--lc-color-lightgray: #f0f0f0;
	--lc-color-mediumgray: #c2c2c7;
	--lc-color-white: #ffffff;
}

[data-theme='dark'] {
	--lc-color-bluegray: #ffffff;
	--lc-color-lavender: #5a1559;
	--lc-color-lightgray: #3f484e;
	--lc-color-mediumgray: #c2c2c7;
	--lc-color-white: #3c4a54;
}
