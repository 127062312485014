@use 'colors';

@keyframes spin {
	0% {
		transform: rotate(0deg);
		opacity: 0%;
	}

	10% {
		opacity: 0%;
	}

	20% {
		opacity: 100%;
	}

	80% {
		opacity: 100%;
	}

	90% {
		opacity: 0%;
	}

	100% {
		transform: rotate(720deg);
		opacity: 0%;
	}
}

.spinner {
	font-size: 10rem;
	width: 2em;
	height: 2em;
	position: relative;

	&.mini {
		font-size: 0.5em;
	}

	.inner {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: 100%;
		animation: spin 3s ease-in-out infinite;

		&::after {
			@include colors.bg-bluegray;

			content: '';
			height: 0.25em;
			width: 0.25em;
			border-radius: 100%;
		}
	}
}
