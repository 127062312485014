$color-light-contrast: var(--lc-color-light-contrast);
$color-dark-contrast: var(--lc-color-dark-contrast);

$color-yellow: var(--lc-color-yellow);
$color-purple: var(--lc-color-purple);
$color-bluegray: var(--lc-color-bluegray);
$color-green: var(--lc-color-green);
$color-lavender: var(--lc-color-lavender);
$color-lightgray: var(--lc-color-lightgray);
$color-mediumgray: var(--lc-color-mediumgray);
$color-white: var(--lc-color-white);

@mixin fg-light-contrast {
	color: $color-light-contrast;
}
@mixin fg-dark-contrast {
	color: $color-dark-contrast;
}

@mixin fg-yellow {
	color: $color-yellow;
}
@mixin fg-purple {
	color: $color-purple;
}
@mixin fg-bluegray {
	color: $color-bluegray;
}
@mixin fg-green {
	color: $color-green;
}
@mixin fg-lavender {
	color: $color-lavender;
}
@mixin fg-lightgray {
	color: $color-lightgray;
}
@mixin fg-mediumgray {
	color: $color-mediumgray;
}
@mixin fg-white {
	color: $color-white;
}

@mixin bg-yellow {
	background-color: $color-yellow;
}
@mixin bg-purple {
	background-color: $color-purple;
}
@mixin bg-bluegray {
	background-color: $color-bluegray;
}
@mixin bg-green {
	background-color: $color-green;
}
@mixin bg-lavender {
	background-color: $color-lavender;
}
@mixin bg-lightgray {
	background-color: $color-lightgray;
}
@mixin bg-mediumgray {
	background-color: $color-mediumgray;
}
@mixin bg-white {
	background-color: $color-white;
}

@mixin block-yellow {
	@include bg-yellow;
	@include fg-light-contrast;
}
@mixin block-purple {
	@include bg-purple;
	@include fg-dark-contrast;
}
@mixin block-bluegray {
	@include bg-bluegray;
	@include fg-white;
}
@mixin block-green {
	@include bg-green;
	@include fg-dark-contrast;
}
@mixin block-lavender {
	@include bg-lavender;
	@include fg-bluegray;
}
@mixin block-lightgray {
	@include bg-lightgray;
	@include fg-bluegray;
}
@mixin block-mediumgray {
	@include bg-mediumgray;
	@include fg-white;
}
@mixin block-white {
	@include bg-white;
	@include fg-bluegray;
}
