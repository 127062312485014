@font-face {
	font-family: Gotham;
	font-weight: 100;
	src: url('/assets/fonts/Gotham-Thin.otf');
}
@font-face {
	font-family: Gotham;
	font-weight: 200;
	src: url('/assets/fonts/Gotham-XLight.otf');
}
@font-face {
	font-family: Gotham;
	font-weight: 300;
	src: url('/assets/fonts/Gotham-Light.otf');
}
@font-face {
	font-family: Gotham;
	font-weight: 400;
	src: url('/assets/fonts/Gotham-Book.otf');
}
@font-face {
	font-family: Gotham;
	font-weight: 500;
	src: url('/assets/fonts/Gotham-Medium.otf');
}
@font-face {
	font-family: Gotham;
	font-weight: 700;
	src: url('/assets/fonts/Gotham-Bold.otf');
}

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

:root {
	--lc-font-text: 'Gotham', 'Montserrat', sans-serif;
	--lc-font-mono: 'Fira Code', monospace;

	--lc-fontsettings-mono: 'calt' off;
	--lc-fontsettings-code: 'calt' on;
}
